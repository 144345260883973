var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid"},[_c('div',{staticClass:"col-12"},[_c('Card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Matrículas")]},proxy:true},{key:"content",fn:function(){return [_c('DataTable',{attrs:{"stripedRows":"","value":_vm.matriculas,"loading":_vm.loading,"paginatorTemplate":"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown","currentPageReportTemplate":"Exibindo {first} a {last} de {totalRecords} matriculas","responsiveLayout":"scroll"},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" Nenhuma matrícula encontrada. ")]},proxy:true},{key:"loading",fn:function(){return [_vm._v(" Carregando. Por favor aguarde. ")]},proxy:true}])},[_c('Column',{attrs:{"field":"cpf","header":"CPF"}}),_c('Column',{attrs:{"field":"nome","header":"Nome"}}),_c('Column',{attrs:{"field":"matricula","header":"Matrícula"}}),_c('Column',{attrs:{"field":"entidadeNome","header":"Entidade"}}),_c('Column',{attrs:{"field":"vinculoNome","header":"Vínculo"}}),_c('Column',{attrs:{"header":"Situação"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_c('Tag',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(
                  data.validacao.servidorApto
                    ? 'O servidor está apto a consignar.'
                    : 'O servidor está inapto a consignar pelo motivo(s): ' +
                      _vm.exibirMotivosInapto(data.validacao.motivos)
                ),expression:"\n                  data.validacao.servidorApto\n                    ? 'O servidor está apto a consignar.'\n                    : 'O servidor está inapto a consignar pelo motivo(s): ' +\n                      exibirMotivosInapto(data.validacao.motivos)\n                ",modifiers:{"bottom":true}}],attrs:{"value":data.validacao.servidorApto
                    ? 'Apto a consignar'
                    : 'Inapto a consignar: ' +
                      _vm.exibirMotivosInapto(data.validacao.motivos),"severity":data.validacao.severity}})]}}])}),_c('Column',{attrs:{"header":"Ações"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Detalhes'),expression:"'Detalhes'",modifiers:{"left":true}}],staticClass:"p-button-rounded mr-2",attrs:{"icon":_vm.loadingDetail ? 'pi pi-spin pi-spinner' : 'pi pi-eye'},on:{"click":function($event){return _vm.detalheServidor(slotProps.data)}}}),_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:('Contracheque'),expression:"'Contracheque'",modifiers:{"left":true}}],staticClass:"p-button-rounded p-button-outlined",attrs:{"icon":_vm.loadingDetail ? 'pi pi-spin pi-spinner' : 'pi pi-id-card'},on:{"click":function($event){return _vm.detalhesContracheque(slotProps.data)}}})]}}])})],1)]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }